

















































import { Component, Vue } from 'vue-property-decorator';
import PaymentModel from '@/models/Payment.model';
import { setterDate, setterAmount } from '@/plugins/utils';
import paymentsRepository from '@/repository/modules/payments.repository';
import ListRowPayment from '@/components/Payments/list-row-payment.vue';

@Component({
  components: {
    ListRowPayment,
  },
})
export default class ListPayments extends Vue {
  page = 1;
  limit = 50;
  total_pages = 1;
  total_results = 0;
  loading = true;
  listPayments: PaymentModel[] = [];

  provider_id_payment: string | null = null;
  card_first_6_digits: string | null = null;
  card_last_4_digits: string | null = null;

  async mounted() {
    this.loadPayments();
  }

  async loadPayments() {
    this.loading = true;
    const { payments, total_results, total_pages } = await paymentsRepository.getListPayments({
      query: this.createQuery(),
      page: this.page,
      limit: this.limit,
    });
    this.listPayments = payments;
    this.total_results = total_results ?? 0;
    this.total_pages = total_pages;
    this.loading = false;
  }

  createQuery() {
    const query: Record<string, any> = {};
    if (this.provider_id_payment) query.provider_id_payment = this.provider_id_payment;
    if (this.card_first_6_digits) query.credit_card_first_6_digits = this.card_first_6_digits;
    if (this.card_last_4_digits) query.credit_card_last_4_digits = this.card_last_4_digits;
    return query;
  }

  setDate = (dater: Date) => setterDate(dater);
  setAmount = (amount: number) => setterAmount(amount);
}
